<template>
  <div class="verify container">
    <h1>Verify Your Account</h1>
    <p>Enter your 4 digit code.</p>

    <b-field>
      <b-input
        size="is-medium"
        type="number"
        placeholder="Code"
        v-model="code"
      ></b-input>
    </b-field>
    <button
      class="button is-fullwidth is-primary is-medium"
      @click="verifyAccount"
    >
      <span>
        Verify
      </span>
    </button>

    <p>
      Didn't get the panchod? Request a new one
      <a href="/request-new-verification"> here</a>
    </p>
  </div>
</template>
<style scoped lang="scss">
.verify {
  min-height: 100vh;

  h1 {
    font-size: 2rem;
    margin: 2rem 0;
  }
}
</style>
<script>
// @ is an alias to /src

export default {
  name: "VerifyAccount",
  components: {},
  data() {
    return {
      code: "",
    };
  },
  methods: {
    async verifyAccount() {
      const response = await this.$store.dispatch("confirmSignup", {
        code: this.code,
      });

      return response;
    },
  },
};
</script>
