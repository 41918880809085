<template>
  <div class="signIn container">
    <h1>Sign In</h1>
    <UserAuth type="signin" />
  </div>
</template>
<style scoped lang="scss">
.signIn {
  min-height: 100vh;

  h1 {
    font-size: 2rem;
    margin: 2rem 0;
  }
}
</style>
<script>
// @ is an alias to /src
import UserAuth from "@/hoc/UserAuth.vue";

export default {
  name: "Home",
  components: {
    UserAuth,
  },
};
</script>
