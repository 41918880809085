<template>
  <div class="verify container">
    <h1>Request a New Verification Code</h1>
    <p>Enter your registered email or phone number.</p>

    <b-field>
      <b-input
        size="is-medium"
        type="text"
        placeholder="Email"
        v-model="email"
      ></b-input>
    </b-field>
    <button
      class="button is-fullwidth is-primary is-medium"
      @click="verifyAccount"
    >
      <span>
        Get Code
      </span>
    </button>
  </div>
</template>
<style scoped lang="scss">
.verify {
  min-height: 100vh;

  h1 {
    font-size: 2rem;
    margin: 2rem 0;
  }
}
</style>
<script>
// @ is an alias to /src

export default {
  name: "RequestNewVerification",
  components: {},
  methods: {
    verifyAccount() {
      return 0;
    },
  },
};
</script>
