<template>
  <div>
    <b-field>
      <b-input
        size="is-medium"
        type="email"
        placeholder="Email"
        v-model="email"
      ></b-input>
    </b-field>

    <b-field>
      <b-input
        size="is-medium"
        type="password"
        placeholder="Password"
        v-model="password"
      ></b-input>
    </b-field>

    <button class="button is-fullwidth is-primary is-medium" @click="connect">
      <span>
        Connect
      </span>
    </button>

    <p>Or sign in with an existing account</p>
    <div class="columns">
      <b-field class="column">
        <button
          class="button is-fullwidth is-primary is-medium"
          @click="signUpWith3rdParty"
        >
          <span>
            Connect with
          </span>
          <span class="icon is-medium">
            <i class="fab fa-google"></i>
          </span>
        </button>
      </b-field>
      <b-field class="column">
        <button
          class="button is-fullwidth is-primary is-medium"
          @click="signUpWith3rdParty"
        >
          <span>
            Connect with
          </span>
          <span class="icon is-medium">
            <i class="fab fa-apple"></i>
          </span>
        </button>
      </b-field>
      <b-field class="column">
        <button
          class="button is-fullwidth is-primary is-medium"
          @click="signUpWith3rdParty"
        >
          <span>
            Connect with
          </span>
          <span class="icon is-medium">
            <i class="fab fa-facebook"></i>
          </span>
        </button>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAuth",
  props: ["authType", "afterConnect"],
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    connect() {
      if (this.authType === "signup") {
        this.signupWithEmail();
      } else {
        this.loginWithEmail();
      }
    },
    async signupWithEmail() {
      const response = await this.$store.dispatch("signUp", {
        username: this.email,
        password: this.password,
      });

      if (response) {
        this.$emit("afterConnect");
      } else {
        this.$emit("afterConnect");
      }
    },
    signUpWith3rdParty() {},
    loginWithEmail() {
      this.$store.dispatch("signIn", {
        username: this.email,
        password: this.password,
      });
    },
  },
};
</script>
